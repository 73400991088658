.container {
    width: 100%;
    height: 80px;
    border: 1px solid black;
}

.mainContainer {
    position: relative;
    width: 100%;
}

.currencyName {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 18px;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 6px;
    padding-right: 6px;
}

.balance {
    font-size: 48px;
    position: relative;
    top: 20px;
    left: 10px;
}

.input {
    font-size: 48px;
    width: 100%;
    height: 100%;
    z-index: 10;
}